import styled from 'styled-components';

import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 32px;

  ${atMinWidth.lg`
    flex-direction: row;
  `}

  & > div {
    flex: 1 0 45%;
    width: 100%;
    justify-content: center;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  gap: 24px;

  ${atMinWidth.lg`
    align-items: flex-start;
    text-align: left;
  `}
`;

export const Heading = styled.h2`
  ${font('display', 'md', '700')}
  color: ${props =>
    props.theme === 'Charcoal' ? colors.base.white : colors.gray[900]};
`;
